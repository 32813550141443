.filterDiv,
.filterAd {
  background-color: #ffffff;
  padding: 30px 3em;
  border: 10px solid transparent;
  width: 99%;
  min-height: 222px;
  margin: 0 0.5%;
  box-shadow: 0 10px 10px 0 rgb(137 154 193 / 10%);
}
.filter-button {
  -webkit-user-select: none;
  user-select: none;
  position: relative;
  height: 22px;
  line-height: 19px;
  padding: 2px 17px 1px;
  cursor: pointer;
  color: hsl(0deg 0% 0% / 70%);
  white-space: nowrap;
  margin-bottom: 15px;
  border-radius: 20px;
}
.filter-flex {
  display: -webkit-box !important;
  display: flex !important;
  flex-wrap: wrap;
}
.filter-button.active {
  background: #4e4e4e;
  color: #fff !important;
  border-color: #4e4e4e;
}
.arrow-down-full {
  text-align: center;
  border-top: 2px dashed #c4c7ce;
  padding: 20px 0px;
  margin-top: 20px;
}
@media screen and (min-width: 1px) and (max-width: 768px) {
  .filterDiv,
  .filterAd {
    padding-bottom: 0px !important;
  }
}
.categoryMobile .ant-row {
  padding: 0 1.5px;
}
