#uploadsPage {
  background: white;
  min-height: 130vh;
  border-radius: 10px;
}
#uploadsPage .uploads-header {
  text-align: center;
}
#uploadsPage .uploads-header h1 {
  color: #3b3b3b;
  padding: 80px 0px;
  margin-bottom: 0px;
}
#uploadsPage .ant-tabs-tab {
  width: 50%;
  float: left;
  display: block;
  border-bottom: 5px solid #f6f8fc;
  margin-left: 0;
}
#uploadsPage .ant-tabs > .ant-tabs-nav .ant-tabs-nav-list {
  width: 100%;
  text-align: center;
  border-top: 1px solid #f6f8fc;
}
#uploadsPage .ant-tabs-tab-btn {
  color: #b0b0b0;
}
#uploadsPage .ant-tabs-tab-active,
#uploadsPage .ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #575757;
}

#uploadsPage .ant-tabs-top > .ant-tabs-nav::before {
  border: none !important;
}
#uploadsPage .input-right .ant-input {
  background-color: rgb(255 255 255 / 12%);
  color: #3b3b3b;
  border: none;
}
#uploadsPage .ant-input-affix-wrapper {
  background-color: rgb(255 255 255 / 12%);
  color: #3b3b3b;
  border: none;
}
#uploadsPage .ant-input-affix-wrapper .ant-input {
  color: #3b3b3b;
}
#uploadsPage .ant-select-dropdown {
  background-color: rgb(39 41 60);
}
#uploadsPage .ant-input {
  background-color: rgb(255 255 255 / 12%);
  color: #3b3b3b;
  border: none;
}
#uploadsPage .ant-select:not(.ant-select-customize-input) .ant-select-selector {
  background-color: rgb(255 255 255 / 12%);
  border: none;
}
#uploadsPage .ant-select-focused {
  z-index: 0;
}
#uploadsPage .ant-input-group-compact .ant-select {
  width: 100px;
  border: 1px solid rgb(100 121 143 / 12%);
}
#uploadsPage .uploadHere {
  width: 90%;
  text-align: center;
  border-style: dashed;
  border-color: #433213;
  margin: 100px auto;
}

#uploadsPage .uploadHere .tips {
  position: absolute;
  top: 0;
  line-height: 600px;
  text-align: center;
  width: 100%;
  left: 0;
}
#uploadsPage .ant-upload.ant-upload-drag {
  position: relative;
  width: 500px;
  height: 300px;
  text-align: center;
  background: rgb(255 255 255 / 7%);
  border: 1px dashed #d9d9d9;
  border-radius: 2px;
  cursor: pointer;
  transition: border-color 0.3s;
  margin: 0 auto;
}
#uploadsPage .ant-upload-hint {
  color: rgb(0 0 0 / 50%);
  font-size: 12px;
}
#uploadsPage .ant-upload-text {
  color: #3b3b3b;
}
#uploadsPage .ant-input-group.ant-input-group-compact {
  max-width: 600px;
  margin: 0 auto;
}
#uploadsPage .ant-input-group.ant-input-group-compact .ant-input {
  width: 500px;
  border: 1px solid rgb(100 121 143 / 12%);
  padding: 5px 11px;
}
#uploadsPage .start-uploads {
  width: 100%;
}
#uploadsPage .start-uploads .ant-btn {
  margin: 0 auto;
  display: block;
  height: 50px;
  width: 300px;
}
#uploadsPage .ant-collapse {
  color: rgb(255 255 255 / 85%);
  background-color: rgb(255 255 255 / 7%);
  border: rgb(255 255 255 / 7%);
  max-width: 600px;
  margin: 0 auto;
}
#uploadsPage .ant-collapse > .ant-collapse-item > .ant-collapse-header {
  color: rgb(0 0 0 / 85%);
}
#uploadsPage .ant-collapse > .ant-collapse-item {
  border-bottom: 1px solid #e6e6e6;
}
#uploadsPage .ant-collapse-content {
  color: rgb(212 212 212 / 85%);
  background-color: rgb(255 255 255 / 7%);
  border-top: 1px solid #090b21;
}
#uploadsPage .manage-spliter {
  display: inline-block;
  height: 20px;
  width: 1px;
  margin: 0 16px;
  opacity: 0.3;
  background-color: #fff;
}
#uploadsPage .manage-left,
#uploadsPage .manage-right,
#uploadsPage .manage-spliter {
  color: #6a6a6a;
  float: left;
}
#uploadsPage .manage-left,
#uploadsPage .manage-right {
  cursor: pointer;
}
#uploadsPage .manage-tab {
  color: #3b3b3b;
  overflow: hidden;
  margin-bottom: 20px;
}
#uploadsPage .ant-tabs-tab-active {
  border-bottom: 5px solid #d3e3fd;
}
#uploadsPage .manage-tab .active {
  color: #3b3b3b;
}
#uploadsPage .ant-pagination-item {
  background-color: rgb(0 0 0 / 3%) !important;
  border: none !important;
}
#uploadsPage .ant-pagination-item a {
  color: rgb(0 0 0 / 85%);
}
#uploadsPage .ant-pagination-item-active {
  background: #deeafd !important;
  border-color: #cacaca !important;
}
#uploadsPage .ant-pagination-item-active a {
  color: #3b3b3b !important;
}
#uploadsPage .ant-pagination-prev .ant-pagination-item-link,
#uploadsPage .ant-pagination-next .ant-pagination-item-link {
  background-color: #ececec !important;
  border: none !important;
}
#uploadsPage .ant-pagination-item-link .anticon {
  color: #3b3b3b;
}
#uploadsPage .loading {
  width: 100%;
  margin: 100px 0px;
  color: #e6f7ff;
  text-align: center;
  overflow: hidden;
  height: auto;
  border-radius: 0;
  animation: unset;
}
#uploadsPage .loading .anticon-loading {
  width: 50px;
  height: 50px;
}
#uploadsPage .loading .anticon svg {
  width: 100%;
  height: 100%;
}
#uploadsPage .uploadsMyVideo {
  position: relative;
  width: 500px;
  height: 300px;
  text-align: center;
  background: rgb(255 255 255 / 7%);
  border: 1px dashed #d9d9d9;
  border-radius: 2px;
  cursor: pointer;
  transition: border-color 0.3s;
  margin: 0 auto;
  display: block;
}
#uploadsPage .ant-upload-drag-container {
  color: #ababab;
  font-size: 12px;
}
#uploadsPage .ant-upload-drag-container svg {
  width: 100%;
  height: 100%;
}
#uploadsPage .selectFile {
  margin: 100px 0px;
}
#uploadsPage .selectFile h3 {
  color: #3b3b3b;
  font-size: 20px;
}
#uploadsPage .selectFile h5 {
  color: #d5d5d5;
  font-size: 18px;
}
#uploadsPage .ant-progress-text {
  color: #3b3b3b;
}
#uploadsPage .thumbnail-input {
  text-align: center;
  background: rgb(255 255 255 / 7%);
  border: 1px dashed;
  padding: 20px 0px;
  margin-top: 10px;
}
#uploadsPage .video-description,
#uploadsPage .video-thumbnail {
  max-width: 600px;
  margin: 10px auto;
  color: #3b3b3b;
}
#uploadsPage .video-description p,
#uploadsPage .video-thumbnail p {
  font-size: 12px;
  margin-top: 10px;
}
#uploadsPage .ant-tabs > .ant-tabs-nav,
.ant-tabs > div > .ant-tabs-nav {
  min-width: 800px;
  margin: 0 auto;
}
#uploadsPage .ant-tabs-tabpane {
  max-width: 800px;
  width: 100%;
  margin: 0 auto;
  padding: 0 10px;
}
#uploadsPage .ant-tabs-ink-bar {
  background: unset;
}
#uploadsPage .loading::before {
  border: unset;
}
.uploadsMobile .ant-tabs > .ant-tabs-nav,
.ant-tabs > div > .ant-tabs-nav {
  min-width: 100% !important;
}
.uploadsMobile .ant-input-group-compact .ant-select {
  width: 20% !important;
}
.uploadsMobile .ant-input-group.ant-input-group-compact .ant-input {
  width: 80% !important;
}
.uploadsMobile .uploadsMyVideo {
  width: 100% !important;
}
#uploadsPage .ant-collapse-content > .ant-collapse-content-box {
  color: #3f3f3f;
}
#uploadsPage .videoName {
  color: #555;
  font-weight: bold;
}
#uploadsPage .manage-title h1 {
  margin: 20px 0px;
}
#uploadsPage .smalltext {
  display: block;
  width: 100%;
}
#uploadsPage .ant-card-bordered .ant-card-cover {
  background-image: unset !important;
  background: #f6f8fc;
}
#uploadsPage .videoPoster {
  margin-top: unset !important;
}
