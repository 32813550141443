@import "~antd/dist/antd.css";
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.dn-slider {
  position: relative;
  margin-bottom: 30px;
  height: 65vh;
  width: 100%;
}
.dn-slider-main-container {
  height: 100%;
  width: 100%;
  z-index: 0;
}
.rn-star-inserted {
  z-index: 0;
}
.dn-slider-image-placeholder {
  height: 100%;
  width: 100%;
  -webkit-transition: opacity 0.2s ease-in-out;
  transition: opacity 0.2s ease-in-out;
  background-size: cover;
  background-position: 50%;
  background-repeat: no-repeat;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  z-index: 0;
}
@-webkit-keyframes opacityShow {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes opacityShow {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
.slider-trasnparent-cover {
  background-color: transparent;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  z-index: -1;
  height: 80%;
  width: 100%;
}
.sidenav-outer-container {
  /** height: 100%; **/
  position: absolute;
  right: 10vw;
  top: 0;
  bottom: 100px;
  z-index: 3;
}
.dn-slider-sidenav-container {
  height: 420px;
  padding: 10px 0;
  position: absolute;
  top: 8vh;
  right: 0;
  border-color: red;
  z-index: 3;
}
.dn-slider-sidenav-container .nav-link-ctn {
  list-style: none;
  width: 264px;
  overflow: hidden;
  background-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(transparent),
    color-stop(22%, rgba(22, 22, 26, 0.8)),
    color-stop(81%, rgba(22, 22, 26, 0.8)),
    to(transparent)
  );
  background-image: -webkit-linear-gradient(
    top,
    transparent,
    rgba(22, 22, 26, 0.8) 22%,
    rgba(22, 22, 26, 0.8) 81%,
    transparent
  );
  background-image: linear-gradient(
    180deg,
    transparent,
    rgba(22, 22, 26, 0.8) 22%,
    rgba(22, 22, 26, 0.8) 81%,
    transparent
  );
  padding: 0 20px !important;
}
.dn-slider-sidenav-container .nav-link {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  line-height: 34px;
  height: calc(36vh / 9);
  padding: 0;
  font-size: calc(15vh / 9);
  color: hsla(0, 0%, 100%, 0.4);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-box-pack: start;
  justify-content: flex-start;
  margin: 9px 0;
}
.dn-slider-sidenav-container .nav-link.active a {
  font-size: calc(20vh / 9);
  font-weight: 600;
  color: #fff !important;
}
.dn-slider-sidenav-container .nav-link a {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 100%;
  line-height: calc(36vh / 9);
}
.dn-slider-bottom-shadow {
  display: none;
  position: absolute;
  width: 100%;
  height: 100px;
  bottom: 0;
  z-index: 1;
  background: #020024;
  background: -webkit-gradient(
    linear,
    left bottom,
    left top,
    from(rgba(0, 0, 0, 0.2)),
    to(transparent)
  );
  background: linear-gradient(1turn, rgba(0, 0, 0, 0.2), transparent);
}
.rn-star-inserted .active {
  opacity: 1 !important;
  animation: 0.2s ease 0s 1 normal none running opacityShow !important;
  /*---- animation: 0.2s ease 0s 1 normal none running opacityShow !important; --*/
}

.dn-silder-bottom {
  position: absolute;
  bottom: 0;
  left: 0;
  list-style: none;
  width: 100%;
  padding: 0 5%;
  height: 200px;
  z-index: 2;
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(rgba(22, 22, 26, 0)),
    to(#090b21)
  );
  background: -webkit-linear-gradient(top, rgba(22, 22, 26, 0), #090b21 100%);
  background: linear-gradient(180deg, rgba(22, 22, 26, 0), #090b21 100%);
}
.indexMobile .tags-all {
  overflow: auto !important;
}
.indexMobile .lookingMore {
  margin-top: 0 !important;
}
.tags-all {
  overflow: hidden;
  margin: 10px 10px;
  width: 100%;
}
.tags-all:hover {
  overflow: overlay;
}
.tag-link {
  margin-bottom: 0;
  width: max-content;
  position: relative;
}
.tag-link-li {
  list-style: none;
  float: left;
  margin-right: 15px;
  overflow: hidden;
}
.tag-link-li a:hover {
  background: #abcdff !important;
}
.tag-link-li a {
  font-size: 14px;
  color: #353535;
  padding: 2px 15px;
  background: #d3e3fd !important;
  border-radius: 10px;
  margin-bottom: 10px;
  display: block;
  line-height: 30px;
}
.more-link {
  position: absolute;
  bottom: 0;
  list-style: none;
  width: 20%;
  right: 5%;
}
.more-link li {
  list-style: none;
  float: left;
  width: 33.3%;
  text-align: center;
  cursor: pointer;
}
.more-link li span {
  color: white;
  margin-bottom: 10px;
  width: 18px;
  height: 18px;
}
.more-link li span svg {
  font-size: 18px;
}
.more-link .more-link-bottom .text {
  color: white;
  font-size: calc(15vh / 9);
}
.top-ranked {
  border-left: solid 20px transparent;
  border-right: solid 20px transparent;
}
.top-ranked ul {
  padding: 10px 20px 10px 20px !important;
  background-color: #1c1d2f;
  border-radius: 3px;
  position: relative;
}
.top-ranked li {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-bottom: 10px;
}
.top-ranked li a {
  line-height: 26px;
  display: block;
  padding-right: 38px;
  width: 100%;
  text-align: left;
  font-size: 0.78em !important;
}
.top-ranked li a span {
  margin-right: 10px !important;
  font-size: 1em !important;
  font-weight: bold !important;
  font-style: italic !important;
}
.top-ranked li:nth-child(1) a span {
  color: #ff4800;
  font-size: 1.2em !important;
}
.top-ranked li:nth-child(2) a span {
  color: #ff7800;
  font-size: 1.2em !important;
}
.top-ranked li:nth-child(3) a span {
  color: #ffb400;
  font-size: 1.2em !important;
}
.top-ranked h3 {
  font-size: 22px;
  font-weight: bold;
  color: #f5f5f5;
  margin-top: 10px;
}
.rn-more {
  float: right;
}
.main-search {
  padding: 50px 20px;
  background: url("./pages/assets/bg_search.jpg");
  background-size: cover;
}
.main-search .ant-input-affix-wrapper-lg {
  border-radius: 99px;
  background: rgb(45 45 45);
  border: none;
}
.main-search h2 {
  text-align: center;
  color: white;
  font-weight: bold;
}
.main-search .ant-input-affix-wrapper > input.ant-input {
  background: transparent;
  color: white;
}
#indexPage .ant-row {
  padding: 4px;
}
/*
#indexPage .ant-col-xs-12 {

  padding: 0 2.5px;

}  */
#indexPage .ant-row {
  margin: 0px auto;
}
#indexPage .lookingMore {
  width: 100%;
  position: relative;
  overflow: hidden;
  height: 45px;
  line-height: 45px;
  margin-top: 10px;
}
.indexMobile .lookingMore a {
  margin-right: 6px;
}
#indexPage .lookingMore a {
  color: #001d35;
}
#indexPage .lookingMore i {
  color: #001d35;
  display: inline-block;
  padding-right: 12px;
  font-size: 15px;
  vertical-align: top;
}
#indexPage .lookingMore h5 {
  float: left;
}
#indexPage .indexCategoryName {
  margin-right: 10px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 22px;
  color: #001d35;
  transition: color 0.2s linear;
  padding-left: 10px;
}
#indexPage .indexCategoryName::before {
  content: " ";
  position: absolute;
  left: 5px;
  bottom: 5px;
  background: linear-gradient(90deg, #3f51b5, rgba(25, 64, 255, 0));
  opacity: 0.4;
  display: inline-block;
  width: 48px;
  height: 10px;
  background-size: 100%;
}
#indexPage .centerPlay {
  position: relative;
  width: 100%;
  height: 30vh;
}
#indexPage .centerPlay img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 100%;
  max-height: 100%;
  z-index: 999999;
}
#indexPage .videoInfoMore {
  margin-bottom: 20px;
}
#indexPage .swiper-3d {
  height: 30vh;
}
#indexPage #swiperTop .swiper-pagination {
  bottom: 35px;
  right: 5px;
  left: unset;
  z-index: 99999 !important;
}
#indexPage #swiperTop .swiper-pagination-bullet-active {
  width: 8px !important;
}
