.search-col-left {
  float: left;
  position: relative;
  display: block;
}
.search-col-right {
  float: left;
  padding-left: 10px;
}
.filterTop {
  border-bottom: 1px solid;
  border-color: hsla(0, 0%, 40%, 0.2);
  margin-bottom: 30px;
  padding-bottom: 15px;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  display: flex;
}
.filterTop span {
  margin-left: 0;
  font-size: 14px;
  color: hsl(0deg 0% 0% / 40%);
  line-height: 34px;
}
.filterTop .white {
  color: black;
}
.key-box {
  height: 28px;
  line-height: 28px;
  display: flex;
  background-color: #f6f8fc;
  color: #323232;
  padding: 0 0.8em;
  font-size: 14px;
  margin-right: 17px;
  min-width: 86px;
}
.key-text {
  max-width: 220px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.key-text .anticon {
  float: right;
  height: 28px;
  padding-right: 0;
  padding-left: 20px;
}
.videoName {
  font-size: 13px;
}
.filterTopRank {
  background-color: #ffffff;
  padding: 30px 3em;
  border: 10px solid transparent;
  width: 99%;
  min-height: 222px;
  margin: 10px 0.5%;
  box-shadow: 0 10px 10px 0 rgb(137 154 193 / 10%);
}
.filterTopRank h4 {
  color: black;
  font-size: 20px;
}
.filterTopRank ul,
.filterTopRank li {
  list-style: none;
}
.filterTopRank li {
  margin-bottom: 10px;
}
.filterTopRank li a {
  height: 26px;
  line-height: 26px;
  width: 170px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: hsl(0deg 0% 0% / 70%);
  transition: all 0.1s ease-out;
  text-decoration: none;
}
.filterTopRank li span {
  background-color: transparent !important;
  color: hsl(0deg 0% 0% / 70%);
  left: 0;
  text-align: center;
  line-height: 26px;
  font-size: 13px;
  padding-right: 15px;
}
.filterTopRank li .points {
  float: right;
}
.filterTopRank .rank1 {
  color: #a33407 !important;
}
.filterTopRank .rank2 {
  color: #b85702 !important;
}
.filterTopRank .rank3 {
  color: #e7a200 !important;
}
.filterTopRank .rankSpan {
  font-size: 18px;
}
.resultMobile .videoName {
  padding-right: 10px;
}
.emptyContent {
  padding: 50px 0px;
  text-align: center;
  width: 100%;
  font-size: 20px;
}
