#accountPage {
  max-width: 950px;
  margin: 0 auto;
  min-height: 80vh;
}
#accountPage .ant-row {
  margin: 200px auto 100px auto;
}
#accountPage h1 {
  font-weight: bolder;
  color: #5a5a5a;
}
#accountPage .account-left,
#accountPage .account-right {
  padding: 0 10px;
  width: 90%;
}
#accountPage .account-right li {
  float: left;
  padding-right: 15px;
}
#accountPage .account-right p {
  color: #6a6a6a;
}
#accountPage .account-right ul {
  position: relative;
  overflow: hidden;
}
#accountPage .ant-input-affix-wrapper {
  margin-bottom: 10px;
  background-color: transparent !important;
  border: unset !important;
  border-bottom: 1px solid #d9d9d9 !important;
}
#accountPage .ant-input-affix-wrapper > input.ant-input {
  padding: 10px !important;
  background: transparent !important;
}
#accountPage .ant-btn {
  width: 100%;
  background: #3f51b5;
  border: none;
  height: 54px;
  padding-left: 30px;
  padding-right: 30px;
}
#accountPage .ant-btn:hover {
  background: #5b69a9;
}
#accountPage .ant-btn span {
  color: white;
}
#accountPage h3 {
  color: #323232;
}
#accountPage .forgetPassword,
#accountPage .registerText {
  margin-top: 10px;
  display: block;
  font-size: 13px;
  color: #525252;
}
#accountPage .ant-input-affix-wrapper:focus,
.ant-input-affix-wrapper-focused {
  box-shadow: unset !important;
  border: none !important;
}
#accountPage .anticon {
  padding: 0 !important;
}
#accountPage .exchangeType {
  width: 200px;
  height: 200px;
  margin: 100px auto;
  text-align: center;
  line-height: 200px;
}
#accountPage .ant-btn {
  margin-bottom: 10px;
}

#accountPage li .anticon {
  padding-right: 10px !important;
}
.accountMobile .account-left,
.accountMobile .account-right {
  width: 100% !important;
}

#accountPage .ant-row h5 {
  margin-bottom: 1.5rem !important;
  color: #767676;
  font-size: 1.05em;
}
#accountPage
  .ant-select-single:not(.ant-select-customize-input)
  .ant-select-selector {
  width: 100%;
  height: 52px;
  padding: 0 11px;
}
#accountPage
  .ant-select-show-search.ant-select:not(.ant-select-customize-input)
  .ant-select-selector
  input {
  height: 100%;
}
#accountPage .ant-select-single .ant-select-selector .ant-select-selection-item,
.ant-select-single .ant-select-selector .ant-select-selection-placeholder {
  line-height: 50px !important;
}
#accountPage
  .ant-select-focused:not(.ant-select-disabled).ant-select:not(
    .ant-select-customize-input
  )
  .ant-select-selector {
  box-shadow: none;
  border: none;
  z-index: 0;
}
#accountPage .ant-select:not(.ant-select-customize-input) .ant-select-selector {
  background-color: rgb(184 184 184 / 8%);
}
.accountMobile .ant-row {
  background: white;
  padding: 50px 0px;
  margin: 10vh 2% !important;
  box-shadow: 0 10px 10px 0 rgb(137 154 193 / 10%);
  border-top: 5px solid #637bff !important;
  border-radius: 5px;
}
#accountPage .account-verify .ant-btn {
  width: unset;
  height: unset;
  padding-left: unset;
  padding-right: unset;
}
#accountPage .account-verify .ant-input-group .ant-input {
  width: calc(100% - 30%) !important;
  padding: 10px !important;
}

#accountPage .account-verify .ant-btn {
  width: calc(100% - 70%) !important;
  padding: 10px !important;
}

#accountPage .account-verify .confirm-verify {
  width: 100% !important;
}
#accountPage .alreadySend {
  background: rgb(63 81 181 / 70%);
}
.accountMobile .account-verify .ant-input {
  background: #f6f8fc;
}
