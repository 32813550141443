.tagFlexLeft {
  border-left: 1px solid hsla(0, 0%, 40%, 0.2);
  border-right: 1px solid hsla(0, 0%, 40%, 0.2);
  min-height: 100vh;
  overflow-y: auto;
}
.tagFlexLeft li {
  text-align: center;
}
.tagFlexLeft li a {
  padding: 10px;
  display: block;
}
.tagFlexLeft li a:hover,
.tagFlexLeft .active {
  background: #171c51;
}
.tagFlexLeft::-webkit-scrollbar {
  width: 10px !important;
  background-color: rgb(43 44 58 / 35%) !important;
}

.tagFlexLeft::-webkit-scrollbar-thumb {
  background-color: #b3b3b3 !important;
  border-radius: 9px !important;
  background-clip: content-box !important;
}

#tagsPage::v-deep .rightList::-webkit-scrollbar-thumb {
  background-color: #b3b3b3 !important;
  border-radius: 9px !important;
  background-clip: content-box !important;
}

.tagFlexLeft::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgb(0 0 0 / 30%) !important;
  border-radius: 10px !important;
  background-color: rgb(43 44 58 / 35%) !important;
}

#tagsPage::v-deep .rightList::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgb(0 0 0 / 30%) !important;
  border-radius: 10px !important;
  background-color: rgb(43 44 58 / 35%) !important;
}
