#myAccountPage {
  margin: 0px auto 0 auto;
  background: white;
  border-radius: 20px;
  padding: 20px;
}
#myAccountPage .ant-tabs > .ant-tabs-nav {
  display: none;
}
#myAccountPage .account-header {
  background: #2f2f66;
  padding: 100px 0px;
  text-align: center;
  border-radius: 3px;
}
#myAccountPage .tablist {
  background: #0d0d17;
}
#myAccountPage .ant-tabs-tab-btn {
  color: #cacaca;
}
#myAccountPage .ant-tabs-nav::before {
  border-bottom: 1px solid #2f2f66;
}
#myAccountPage .ant-tabs-ink-bar {
  background: white;
}
#myAccountPage .ant-tabs-tab {
  flex-grow: 1;
  margin-right: 0px;
  width: 100%;
  text-align: center;
}
#myAccountPage .password-body .ant-input-affix-wrapper {
  margin-bottom: 10px;
}
#myAccountPage .input-body label {
  display: block;
  float: left;
  color: #323232;
  width: 100px;
}
#myAccountPage .input-body .input-right {
  float: left;
  display: flex;
  width: 300px;
}
#myAccountPage .input-body {
  width: 100%;
  display: block;
  overflow: hidden;
  margin-bottom: 10px;
}
#myAccountPage .myinfo-body {
  max-width: 450px;
  margin: 0 auto;
}
#myAccountPage .avatar-body {
  margin: 100px 0px;
}
#myAccountPage .avatar-body .ant-avatar {
  margin: 2.5px;
  width: 64px;
  height: 64px;
  border: 2px solid transparent;
  background: transparent;
}
#myAccountPage .tabPane-body .ant-timeline-item-label {
  color: #464646;
}
#myAccountPage .tabPane-body .ant-timeline-item-content {
  color: #464646;
  font-weight: bold;
}
#myAccountPage .tabPane-body .ant-timeline-item-head-blue {
  color: #ffffff;
  border-color: #7d7dff;
}
#myAccountPage .ant-table-pagination.ant-pagination {
  display: none;
}
#myAccountPage .shareTips {
  font-size: 12px;
  max-width: 380px;
  margin: 0px auto 30px auto;
  color: #303030;
  text-align: left;
  padding: 0px 20px 0px 0px;
}
#myAccountPage .ant-table {
  max-width: 700px;
  margin: 0 auto;
}
#myAccountPage .ant-pagination-item,
.ant-pagination-jump-next {
  background-color: #e9e9e9 !important;
  border: none !important;
}
#myAccountPage .ant-pagination-item a {
  color: rgb(5 5 5 / 85%);
}
#myAccountPage .ant-pagination-item-active {
  background: #d3e3fd !important;
  border-color: #d3e3fd !important;
}
#myAccountPage .ant-pagination-item-active a {
  color: black !important;
}
#myAccountPage .ant-pagination-prev .ant-pagination-item-link,
.ant-pagination-next .ant-pagination-item-link,
.ant-pagination-prev,
.ant-pagination-next {
  background-color: #e9e9e9 !important;
  border: none !important;
}
#myAccountPage .ant-pagination-item-link .anticon {
  color: black;
}
#myAccountPage .input-right .ant-input {
  background-color: transparent;
  color: #424242;
}
#myAccountPage .ant-input-affix-wrapper {
  background-color: rgb(161 161 161 / 12%);
  color: #232323;
  border: none;
}
#myAccountPage .ant-input-affix-wrapper .ant-input {
  color: #4e4e4e;
}
#myAccountPage .password-body .ant-input-affix-wrapper .ant-input {
  background-color: transparent !important;
}
#myAccountPage .figures {
  display: flex;
  flex-shrink: 0;
  max-width: 586px;
  justify-content: space-between;
  background-color: #f7f8fa;
  border-radius: 2px;
  padding: 20px 55px;
  box-sizing: border-box;
  margin: 0 auto;
}

@media (max-width: 600px) {
  #myAccountPage .figures {
    min-width: unset;
    width: 100%;
    margin-top: 20px;
  }
}

#myAccountPage .signin-calendar {
  width: 100%;
  margin-top: 24px;
}

#myAccountPage .code-calender {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: 24px auto;
  max-width: 240px;
}

@media (max-width: 600px) {
  #myAccountPage .code-calender {
    margin-top: 20px;
    width: 100%;
  }
}

#myAccountPage .code-calender .btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 240px;
  height: 56px;
  border-radius: 4px;
  font-size: 18px;
  font-weight: 500;
  color: #fff;
}

@media (max-width: 600px) {
  #myAccountPage .code-calender .btn {
    width: 100%;
  }
}

#myAccountPage .code-calender .signin {
  background-color: #637bff;
  color: #fff;
  box-shadow: 0 0 16px rgb(30 128 255 / 24%);
  border: none;
}

#myAccountPage .code-calender .signin:hover {
  background-color: #388eff;
}

#myAccountPage .code-calender .signin:active {
  background: #1c78f0;
}

#myAccountPage .code-calender .signedin {
  background-color: #e8f3ff;
  color: #637bff;
}

#myAccountPage .lottery-icon {
  margin-right: 6px;
  width: 24px;
  height: 24px;
  margin-bottom: -4px;
}
#myAccountPage .figure-card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-right: 10px;
}

#myAccountPage .figure-card .figure {
  font-weight: 700;
  font-size: 32px;
  color: #1d2129;
  line-height: 36px;
  font-family: DIN Alternate;
  white-space: nowrap;
  margin-right: 12px;
  position: relative;
  top: 1px;
}

#myAccountPage .figure-card .active {
  color: #637bff;
}

#myAccountPage .figure-card .attention {
  display: flex;
  align-items: flex-end;
  margin-top: 4px;
}

#myAccountPage .figure-card .attention .text {
  color: #86909c;
  font-weight: 400;
  font-size: 14px;
  white-space: nowrap;
}

#myAccountPage .figure-card .attention .tooltip {
  position: relative;
  top: 1px;
  margin-left: 4px;
}

#myAccountPage .figure-card .attention .tooltip .byte-tooltip {
  width: 200px;
}

#myAccountPage .byte-tooltip--light,
#myAccountPage .figure-card .attention .tooltip .byte-tooltip {
  color: #4e5969;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
}
#myAccountPage .vipICON {
  background: #637bff;
  color: white;
  border-radius: 99px;
  padding: 0px 10px;
}
#myAccountPage .code-calender .anticon {
  color: white !important;
}
#myAccountPage .cleanList {
  color: black;
  font-size: 12px;
  float: right;
  line-height: 32px;
}
#myAccountPage .deleteItem {
  position: absolute;
  top: -40px;
  left: 10px;
  background: rgb(255 255 255 / 86%);
  z-index: 999;
  border-radius: 99px;
  box-shadow: rgb(50 50 93 / 25%) 0px 6px 12px -2px,
    rgb(0 0 0 / 30%) 0px 3px 7px -3px;
  text-align: center;
  color: #637bff;
  padding: 0 10px;
}

#myAccountPage .deleteItem .anticon {
  padding: 0;
}
.myAccountMobile .ant-table-thead > tr > th {
  width: 20%;
  padding: 10px 0px;
  text-align: center;
  font-size: 12px;
}
.myAccountMobile .ant-table-tbody > tr > td {
  width: 20%;
  padding: 10px 0px;
  text-align: center;
  font-size: 12px;
}
.myAccountMobile .input-body .input-right {
  width: unset !important;
}
#myAccountPage
  .ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
  background: #fbfbfb;
}
