#detailPage .videoPoster img {
  position: absolute;
  left: 0px;
  bottom: 0px;
  right: 0px;
  width: 100%;
}
#detailPage .c-player {
  width: 100%;
  height: 100%;
}
#detailPage .video-js {
  width: 100% !important;
  height: 100% !important;
}
#detailPage .playerToolBar {
  display: flex;
  padding: 20px 10px;
  background: #ffffff;
  box-shadow: 0 10px 10px 0 rgb(137 154 193 / 10%);
}
#detailPage .playerToolBar .anticon svg {
  font-size: 25px;
}
#detailPage .playerToolBar .toolBarLeft .like,
#detailPage .playerToolBar .toolBarLeft .dislike,
#detailPage .playerToolBar .toolBarLeft .favorite,
#detailPage .playerToolBar .toolBarLeft .share,
#detailPage .playerToolBar .toolBarLeft .mobileQrcode {
  display: flex;
  float: left;
  margin: 0 10px;
  cursor: pointer;
}
#detailPage .playerToolBar .toolBarLeft .like:hover,
#detailPage .playerToolBar .toolBarLeft .dislike:hover,
#detailPage .playerToolBar .toolBarLeft .favorite:hover,
#detailPage .playerToolBar .toolBarLeft .share:hover,
#detailPage .playerToolBar .toolBarLeft .mobileQrcode:hover {
  color: #bbbbbb;
}
#detailPage .playerToolBar .toolBarRight .pageFullScreen,
#detailPage .playerToolBar .toolBarRight .node,
#detailPage .playerToolBar .toolBarRight .report {
  display: flex;
  float: left;
  margin: 0 10px;
  cursor: pointer;
}
#detailPage .playerToolBar .toolBarRight .pageFullScreen:hover,
#detailPage .playerToolBar .toolBarRight .node:hover,
#detailPage .playerToolBar .toolBarRight .report:hover {
  color: #bbbbbb;
}
#detailPage .toolBarLeft {
  width: 50%;
  float: left;
}
#detailPage .toolBarRight {
  float: right;
  width: 50%;
  display: flex;
  justify-content: flex-end;
}
#detailPage .flexTitle {
  font-size: 22px;
  font-weight: 700;
  color: #001d35;
  margin: 10px 0px;
}
#detailPage .flexInfo {
  display: flex;
}
#detailPage .flexInfo .spanText {
  color: rgb(0 29 53 / 75%);
  font-size: 14.5px;
  margin-right: 15px;
}
#detailPage .tagUl {
  list-style: none;
  overflow: hidden;
  margin: 20px 0px;
}
#detailPage .tagName {
  float: left;
  background-color: #ffffff;
  color: hsl(0deg 0% 0% / 69%);
  padding: 0.1em 0;
  min-width: 55px;
  font-size: 12px;
  text-align: center;
  margin-right: 10px;
  cursor: pointer;
}
#detailPage .commentsArea {
  display: block;
  overflow: hidden;
}
#detailPage .commentsArea h4 {
  display: inline;
  line-height: 36px;
  margin-right: 8px;
  font-size: 22px;
  color: black;
}
#detailPage .commentsArea h4 .anticon-message {
  position: relative;
  font-size: 26px;
  color: #272727;
}
#detailPage .commentsArea .ant-comment .ant-input {
  min-height: 12em;
  padding: 4px 15px;
  position: relative;
  box-sizing: border-box;
  transition: all 0.3s ease-in-out;
  color: black;
  background-color: #ffffff;
  border: none;
}
#detailPage .commentParent .ant-comment .ant-input {
  min-height: 50px !important;
}
#detailPage .flexInfo .anticon-fire {
  color: #fe6f71;
}
#detailPage .btn-bottom {
  position: absolute;
  right: 0px;
  bottom: 10px;
  width: 100%;
  left: 0;
  padding: 0 10px;
}
#detailPage .btn-bottom .ant-btn {
  float: right;
  border-radius: 99px;
}
#detailPage .ant-modal-content .ant-btn {
  font-size: 14px;
  border-radius: 14px;
  background: linear-gradient(45deg, #5562a0 10%, #b687c3);
  cursor: pointer;
  position: relative;
  width: 164px;
  height: 29px;
  display: -webkit-box;
  display: flex;
  justify-content: space-around;
  -webkit-box-align: center;
  align-items: center;
  padding: 0 2px;
  margin: 0 auto;
  border-color: transparent;
  box-shadow: none !important;
}
#detailPage .ant-modal-content .ant-btn:hover {
  background: linear-gradient(45deg, #5562a0 10%, #b687c3) !important;
  border-color: transparent !important;
}
#detailPage .ant-modal-content .ant-btn:hover > span {
  color: white !important;
}
#detailPage .ant-modal-content .ant-btn > span {
  color: white;
}
#detailPage .ant-modal-content .ant-btn:hover {
  background: white;
  border-color: white;
}
#detailPage .ant-modal-content .ant-btn:hover > span {
  color: black;
}
.ant-radio-wrapper span {
  color: #3e3e3e;
}

.ant-radio-input:focus + .ant-radio-inner {
  box-shadow: 0 0 0 3px white;
  background: white;
}
.ant-radio-wrapper:hover .ant-radio,
.ant-radio:hover .ant-radio-inner,
.ant-radio-input:focus + .ant-radio-inner {
  border-color: #2b2c3a;
}
.ant-btn:hover,
.ant-btn:focus {
  border-color: white;
}
.ant-radio-wrapper:hover .ant-radio,
.ant-radio:hover .ant-radio-inner,
.ant-radio-input:focus + .ant-radio-inner {
  border-color: #3b3e61;
}
.ant-comment-content-author-name > * {
  color: white !important;
}
.ant-comment-inner {
  padding: 0;
}

.ant-comment-actions {
  margin-top: 0;
  margin-bottom: 20px;
}
#detailPage .buyTips {
  color: white;
  position: relative;
  overflow: hidden;
  line-height: 50px;
  height: 50px;
  padding: 0 20px;
  background-image: -webkit-gradient(
    linear,
    left top,
    right top,
    color-stop(0, #ffe4b2),
    to(#c79354)
  );
  background-image: linear-gradient(90deg, #ffe4b2, #c79354);
  background-repeat: repeat-x;
}
#detailPage .buyTips .buyLeft {
  float: left;
  color: #664620;
  font-size: 12px;
}
#detailPage .buyTips .buyRight {
  float: right;
}
#detailPage .buyTips .ant-btn {
  border: none;
  width: 200px;
  cursor: pointer;
  background-color: rgb(47 47 47);
}
#detailPage .buyTips .ant-btn span {
  color: white;
}
#detailPage .tagName a {
  color: #5c5c5c;
}
.detailMobile .tagName {
  margin-bottom: 10px;
}
.detailMobile .flexInfo .spanText {
  font-size: 11px !important;
  margin-right: 5px !important;
}
#detailPage .ant-comment-content-author-name a {
  color: black;
}
#detailPage .ant-comment-actions > li > span {
  color: black !important;
}
#detailPage .media-action ul {
  list-style: none;
}
#detailPage .media-action li {
  width: 20%;
  float: left;
  text-align: center;
}
#detailPage .media-action .anticon {
  font-size: 30px;
  color: black;
  width: 100%;
  padding-right: 0;
}
#detailPage .media-action {
  border-bottom: 1px solid #dcdcdc;
  overflow: hidden;
  padding: 10px 0px;
}
.detailMobile .flexTitle {
  font-size: 17px !important;
}
.detailMobile .ant-comment {
  padding: 0 10px;
}
.detailMobile .commentsArea .ant-comment .ant-input {
  min-height: unset !important;
  height: 40px;
  line-height: 40px;
  overflow: hidden;
  padding: 0 10px !important;
}
#detailPage .commentsTitle {
  margin-bottom: 20px;
}
#detailPage .ant-dropdown-menu {
  background-color: #f6f8fc;
}
#detailPage .ant-dropdown {
  max-width: 20vw;
}
#detailPage .ant-dropdown-menu-item,
.ant-dropdown-menu-submenu-title {
  padding: 5px !important;
}
.detailMobile .ant-form-item-control-input-content .ant-btn-primary {
  border-color: #506bff;
  background: #637bff;
}
.detailMobile .ant-col-xs-12 {
  margin-bottom: 10px;
}
.detailMobile .ant-card-body {
  padding: 0 !important;
}
.comment-drawer .ant-drawer-body ul {
  border-bottom: unset;
  padding-bottom: unset;
}
.comment-drawer .ant-drawer-body {
  padding: 24px 0px 100px 0px;
}
.comment-drawer .commentsArea {
  padding: 10px 20px;
  bottom: 0;
  width: 100%;
}
.comment-drawer .commentParent .ant-comment {
  position: unset;
}
.comment-drawer .ant-comment {
  position: absolute;
  bottom: 0;
  width: 100%;
  left: 0;
  padding: 0 10px;
}
.ant-card {
  width: 100%;
}
.ml-media-meta .ml-media-meta-comments {
  padding: 18px 10px !important;
  position: relative;
  border-bottom: 1px solid #2d2d2d;
}
.ml-content-header {
  padding: 10px 0px;
  line-height: 22px;
  display: block;
  width: 100%;
  text-transform: capitalize;
  position: relative;
  margin: 0;
  text-align: left;
}
.ml-content-header .ml-content-header-title {
  padding: 0 10px 5px 10px;
  display: block;
  color: #434343 !important;
  font-family: Roboto, arial, sans-serif !important;
  font-size: 20px;
  font-weight: 700;
  text-decoration: none;
}
.ml-content-header .ml-content-header-sub-title {
  display: block;
  text-align: left;
  font-family: Roboto, arial, sans-serif;
  font-size: 12px;
  color: #8f8f8f;
  padding: 4px 0 0;
  line-height: 16px;
  text-transform: none;
  font-weight: 400;
  padding-left: 10px;
}
.ml-content-float-right .anticon {
  position: absolute;
  top: 0;
  right: 0;
  line-height: 69px;
}
.ml-media-meta-comments {
  border-top: 1px solid #dcdcdc;
  border-bottom: 1px solid #dcdcdc;
}
.comments-count {
  font-size: 15px;
  color: white;
}
.ml-meta-comments-count {
  background: #637bff;
  border-radius: 99px;
  color: white;
  width: 25px;
  padding: 0px 7px;
  margin-left: 5px;
}
.ant-drawer textarea.ant-input {
  padding: 0 15px;
}
