#productPage {
    background: #fff;
    border-radius: 20px
}

#productPage .filterTop {
    margin-bottom: 0
}

#productPage .filterAd,
#productPage .filterDiv {
    background: transparent none repeat 0 0/auto auto padding-box border-box scroll;
    background: initial;
    border: initial;
    box-shadow: none;
    padding: 20px !important
}

@media screen and (min-width:1px) and (max-width:769px) {
    #productPage {
        border-radius: 0 !important;
        height: auto;
        padding: 10px !important
    }
}