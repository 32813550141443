#actorPage {
    background: #fff;
    border-radius: 20px;
    height: 80vh;
    padding: 70px
}

@media screen and (min-width:1px) and (max-width:769px) {
    #actorPage {
        border-radius: 0 !important;
        height: auto;
        padding: 20px 40px !important
    }
}